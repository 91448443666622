import React from "react";
import Header from "../Header/Header";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
// import AssetCheckoutImage from "../../assets/images/AssetCheckoutImage.png";
const AssetCheckout = () => {
  return (
    <div>
      <Header />
      <CheckoutForm />
    </div>
  );
};

export default AssetCheckout;
