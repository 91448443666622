import React, { useRef,useState } from 'react';
import { Button} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "../Dashboard/Dashboard.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function UploadExcel() {
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleFileUpload = async () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    
    if (file) {
        try {
          var myHeaders=new Headers();
          myHeaders.append("Content-Type","application/pdf");
          var requestOption={
            method:'POST',
            headers: myHeaders,
            body: file,
            redirect: "follow"
          }
          var postresponse = await fetch(
            "https://q7ghj4lavb.execute-api.us-east-1.amazonaws.com/dev/am/upload",
            requestOption
          ).then((response) => {
            if(!response.ok) throw new Error(response);
            else return response.json();
          });

          // Handle the response here
          console.log("Response:", postresponse);
          setOpen(true);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      };
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        File Uploaded successfully!
        </Alert>
      </Snackbar>
      <Button
        onClick={handleFileUpload}
        component="label"
        variant="outlined"
        startIcon={<UploadFileIcon />}
      >
        Upload Excel
      </Button>
      <input
        type="file"
        accept=".xlsx"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </>
  );
}

export default UploadExcel;