import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import AssetCheckout from "./Components/AssetCheckout/AssetCheckout";

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/create-new" element={<AssetCheckout />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
