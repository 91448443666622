import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  Box,
  Stepper,
  Step,
  StepButton,
  Button,
  MenuItem,
  TextField,
  Grid,
  Select,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import "./CheckoutForm.css";
import deviceTypeList from "../Dashboard/DeviceType";

function CheckoutFormtest() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    // 👇️ navigate to Dashboard
    navigate("/dashboard");
  };
  const steps = ["Requester Details", "Asset Details", "Confirm", "Create"];
  const [activeStep, setActiveStep] = useState(0);
  const newFormData = {
    Username: "",
    Location: "",
    Order: "",
    ModelNo: "",
    Issuedby: "",
    CheckoutDate: dayjs().format("YYYY-MM-DD"),

    DeviceType: "",
    device_type: "",
    DEVICES: "",
    Qty: 1,
    RITMNumber: "",
    PONumber: "",
    PRNumber: "",
    Assetcode: "",
    ServiceTag: "",
    Status: "",
    FacilityName: "",
    UserEID: "",
    DUName: "",
    Projectmanager: "",
    WBSeUsed: "",
    Link: "",
    uuid: "",
    // other form fields
  };
  const [formData, setFormData] = useState(state ? state : newFormData);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditTrue, setIsEditTrue] = useState(false);
  const handleDeviceTypeChange = (e) => {
    const selectedDeviceType = e.target.value;
    setFormData({ ...formData, DeviceType: selectedDeviceType });
  };
  const editEntry = async (formData) => {
    // jugaad
    formData.DeviceType=formData["device_type"];
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOption = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: "follow",
      };
      var postresponse = await fetch(
        "https://q7ghj4lavb.execute-api.us-east-1.amazonaws.com/dev/am/edit",
        requestOption
      ).then((response) => {
        if (!response.ok) throw new Error(response);
        else return response.json();
      });

      // Handle the response here
      console.log("EditResponse:", postresponse);
      setFormData(newFormData);
      setIsLoading(false);
      setIsEditTrue(true);
    } catch (error) {
      console.log("Error uploading file:", error);
    }
  };

  const createEntry = async (formData) => {
    // jugaad
    formData["device_type"] = formData.DeviceType;
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOption = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: "follow",
      };
      var postresponse = await fetch(
        "https://q7ghj4lavb.execute-api.us-east-1.amazonaws.com/dev/am/create",
        requestOption
      ).then((response) => {
        if (!response.ok) throw new Error(response);
        else return response.json();
      });

      // Handle the response here
      console.log("CreateResponse:", postresponse);
      setFormData(newFormData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const handleSubmit = () => {
    // e.preventDefault();
    if (validFormData()) {
      handleNext();
      formData.uuid === "" ? createEntry(formData) : editEntry(formData);
      console.log(formData);
    }
  };
  const validFormData = () => {
    if (
      formData.DEVICES === "" ||
      formData.Issuedby === "" ||
      formData.DeviceType === "" ||
      formData.UserEID === ""
    ) {
      return false;
    }
    return true;
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    //   console.log(formData);
  };
  const handleDateChange = (value) => {
    setFormData({
      ...formData,
      CheckoutDate: dayjs(value.$d).format("YYYY-MM-DD"),
    });
  };
  return (
    <Box sx={{ margin: "1rem 3rem" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} disabled={index === 3}>
            <StepButton onClick={handleStep(index)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>

      <div
        sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        {activeStep === 0 && (
          <div>
            <React.Fragment>
              <Grid
                container
                justifyContent="space-evenly"
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}
                margin="1rem 0rem"
              >
                <Grid item xs={6}>
                  <p className="form-label">Name</p>
                  <TextField
                    name="Username"
                    value={formData.Username}
                    className={
                      formData.Username === "" ? "defaultField" : "filledField"
                    }
                    placeholder="User Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="form-label">Email Id <span style={{ color: "red" }}>*</span></p>
                  <TextField
                    name="UserEID"
                    value={formData.UserEID}
                    className={
                      formData.UserEID === "" ? "defaultField" : "filledField"
                    }
                    placeholder="User Email Id"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="form-label">Facility Name</p>
                  <TextField
                    name="FacilityName"
                    value={formData.FacilityName}
                    className={
                      formData.FacilityName === ""
                        ? "defaultField"
                        : "filledField"
                    }
                    placeholder="Facility Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="form-label">DU Name</p>
                  <TextField
                    name="DUName"
                    value={formData.DUName}
                    className={
                      formData.DUName === "" ? "defaultField" : "filledField"
                    }
                    placeholder="DU Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="form-label">Project Manager</p>
                  <TextField
                    name="Projectmanager"
                    value={formData.Projectmanager}
                    className={
                      formData.Projectmanager === ""
                        ? "defaultField"
                        : "filledField"
                    }
                    placeholder="Project Manager"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="form-label">Location</p>
                  <TextField
                    name="Location"
                    value={formData.Location}
                    className={
                      formData.Location === "" ? "defaultField" : "filledField"
                    }
                    placeholder="Location"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="form-label">
                    Device <span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <TextField
                    name="DEVICES"
                    value={formData.DEVICES}
                    className={
                      formData.DEVICES === "" ? "defaultField" : "filledField"
                    }
                    placeholder="Device"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className="form-label">
                    Quantity <span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <TextField
                    name="Qty"
                    value={formData.Qty}
                    className={
                      formData.Qty === "" ? "defaultField" : "filledField"
                    }
                    type="number"
                    placeholder="Quantity"
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                mr: 1,
                backgroundColor: "#331773 !important",
                borderRadius: "3rem",
                padding: "0.5rem 3rem",
                marginTop: "3rem",
                marginBottom: "6rem",
              }}
            >
              <span className="NextButtonText">Next</span>
            </Button>
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <React.Fragment>
              <Grid
                container
                justifyContent="space-evenly"
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}
                margin="1rem 0rem"
              >
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">
                    Device Type <span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={formData.DeviceType || formData.device_type || ""}
                    className={
                      formData.DeviceType === "" && formData.device_type === ""
                        ? "defaultField"
                        : "filledField"
                    }
                    onChange={handleDeviceTypeChange}
                    fullWidth
                  >
                    <MenuItem value="">Select Device Type</MenuItem>
                    {deviceTypeList.map((device) => (
                      <MenuItem key={device.value} value={device.value}>
                        {device.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">Order</p>
                  <TextField
                    name="Order"
                    value={formData.Order}
                    className={
                      formData.Order === "" ? "defaultField" : "filledField"
                    }
                    placeholder="Name of the Order May it be Capex or something"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">
                    Device <span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <TextField
                    name="DEVICES"
                    value={formData.DEVICES}
                    className={
                      formData.DEVICES === "" ? "defaultField" : "filledField"
                    }
                    placeholder="Device"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">Link</p>
                  <TextField
                    name="Link"
                    value={formData.Link}
                    className={
                      formData.Link === "" ? "defaultField" : "filledField"
                    }
                    placeholder="Asset Link"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">Model No.</p>
                  <TextField
                    name="ModelNo"
                    value={formData.ModelNo}
                    className={
                      formData.ModelNo === "" ? "defaultField" : "filledField"
                    }
                    placeholder="Model Number."
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">RITM No.</p>
                  <TextField
                    name="RITMNumber"
                    value={formData.RITMNumber}
                    className={
                      formData.RITMNumber === ""
                        ? "defaultField"
                        : "filledField"
                    }
                    placeholder="RITM No."
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">PO No.</p>
                  <TextField
                    name="PONumber"
                    value={formData.PONumber}
                    className={
                      formData.PONumber === "" ? "defaultField" : "filledField"
                    }
                    placeholder="PO Number."
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">PR No.</p>
                  <TextField
                    name="PRNumber"
                    value={formData.PRNumber}
                    className={
                      formData.PRNumber === "" ? "defaultField" : "filledField"
                    }
                    placeholder="PR Number."
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">
                    Issued By <span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <TextField
                    name="Issuedby"
                    value={formData.Issuedby}
                    className={
                      formData.Issuedby === "" ? "defaultField" : "filledField"
                    }
                    placeholder="Issuer Name"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <p className="form-label">
                    Date <span style={{ color: "red" }}>*</span>{" "}
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="CheckoutDate"
                      value={dayjs(formData.CheckoutDate)}
                      className={
                        formData.CheckoutDate === ""
                          ? "defaultField"
                          : "filledField"
                      }
                      onChange={(e) => handleDateChange(e)}
                      //   sx={{ width: "20ch" }}
                      format="YYYY-MM-DD"
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </React.Fragment>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                mr: 1,
                backgroundColor: "#331773 !important",
                borderRadius: "3rem",
                padding: "0.5rem 3rem",
                marginTop: "3rem",
                marginBottom: "6rem",
              }}
            >
              <span className="NextButtonText">Next</span>
            </Button>
          </div>
        )}
        {activeStep === 2 && (
          <React.Fragment>
            <Grid container spacing={2} margin="2rem 0rem">
              <Grid item xs={12}>
                <p className="formName">Requester Details</p>
                <div className="Requester Details">
                  <Grid
                    container
                    justifyContent="space-evenly"
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}
                    margin="1rem 0rem"
                  >
                    <Grid item xs={6}>
                      <p className="form-label">Name</p>
                      <TextField
                        name="UserName"
                        value={formData.Username}
                        className={
                          formData.Username === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className="form-label">Email Id <span style={{ color: "red" }}>*</span></p>
                      <TextField
                        name="UserEID"
                        value={formData.UserEID}
                        className={
                          formData.UserEID === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className="form-label">Facility Name</p>
                      <TextField
                        name="FacilityName"
                        value={formData.FacilityName}
                        className={
                          formData.FacilityName === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className="form-label">Du Name</p>
                      <TextField
                        name="DUName"
                        value={formData.DUName}
                        className={
                          formData.DUName === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className="form-label">Project Manager</p>
                      <TextField
                        name="Projectmanager"
                        value={formData.Projectmanager}
                        className={
                          formData.Projectmanager === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className="form-label">Location</p>
                      <TextField
                        name="Location"
                        value={formData.Location}
                        className={
                          formData.Location === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className="form-label">
                        Device <span style={{ color: "red" }}>*</span>{" "}
                      </p>
                      <TextField
                        name="DEVICES"
                        value={formData.DEVICES}
                        className={
                          formData.DEVICES === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className="form-label">
                        Quantity <span style={{ color: "red" }}>*</span>{" "}
                      </p>
                      <TextField
                        name="Qty"
                        value={formData.Qty}
                        className={
                          formData.Qty === "" ? "defaultField" : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <p className="formName">Asset Details</p>
                <div className="AssetDetails">
                  <Grid
                    container
                    justifyContent="space-evenly"
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}
                    margin="1rem 0rem"
                  >
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">
                        Device Type <span style={{ color: "red" }}>*</span>{" "}
                      </p>
                      <TextField
                        name="DeviceType"
                        value={formData.DeviceType || formData.device_type || ""}
                        className={
                          formData.DeviceType === "" && formData.device_type === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">Order</p>
                      <TextField
                        name="Order"
                        value={formData.Order}
                        className={
                          formData.Order === "" ? "defaultField" : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">
                        Device <span style={{ color: "red" }}>*</span>{" "}
                      </p>
                      <TextField
                        name="DEVICES"
                        value={formData.DEVICES}
                        className={
                          formData.DEVICES === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">Link</p>
                      <TextField
                        name="Link"
                        value={formData.Link}
                        className={
                          formData.Link === "" ? "defaultField" : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">Model No.</p>
                      <TextField
                        name="ModelNo"
                        value={formData.ModelNo}
                        className={
                          formData.ModelNo === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">RITM No.</p>
                      <TextField
                        name="RITMNumber"
                        value={formData.RITMNumber}
                        className={
                          formData.RITMNumber === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">PO No.</p>
                      <TextField
                        name="PONumber"
                        value={formData.PONumber}
                        className={
                          formData.PONumber === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">PR No.</p>
                      <TextField
                        name="PRNumber"
                        value={formData.PRNumber}
                        className={
                          formData.PRNumber === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">
                        Issued By <span style={{ color: "red" }}>*</span>{" "}
                      </p>
                      <TextField
                        name="Issuedby"
                        value={formData.Issuedby}
                        className={
                          formData.Issuedby === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <p className="form-label">
                        Date <span style={{ color: "red" }}>*</span>{" "}
                      </p>
                      <TextField
                        name="CheckoutDate"
                        value={formData.CheckoutDate}
                        className={
                          formData.CheckoutDate === ""
                            ? "defaultField"
                            : "filledField"
                        }
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={validFormData() ? "SubmitButtonValid" : "SubmitButton"}
              sx={{
                mr: 1,
                borderRadius: "3rem",
                padding: "0.5rem 3rem",
                marginTop: "3rem",
                marginBottom: "6rem",
              }}
            >
              <span
                className={
                  validFormData() ? "NextButtonText" : "SubmitButtonText"
                }
              >
                {formData.uuid === "" ? "Create" : "Update"}
              </span>
            </Button>
          </React.Fragment>
        )}
        {activeStep === 3 && (
          <React.Fragment>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2rem",
                }}
              >
                {" "}
                <CircularProgress />{" "}
              </Box>
            ) : (
              <>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <div className="successMessage">
                    <p className="checked-out-confirm">
                      <CheckCircleOutlinedIcon color="success" /> Asset{" "}
                      {isEditTrue ? "updated" : "created"} successfully
                    </p>
                    <p className="checkout-message">
                      Success! Your asset has been{" "}
                      {isEditTrue ? "updated" : "added"}.
                    </p>
                    {isEditTrue ? (
                      ""
                    ) : (
                      <Link to="/create-new" onClick={handleStep(0)}>
                        Add a new Asset
                      </Link>
                    )}
                  </div>
                  <Box sx={{ flex: "1 1 auto" }} />
                </Box>
                <Button
                  variant="contained"
                  onClick={navigateToDashboard}
                  sx={{
                    backgroundColor: "#331773",
                    color: "#ffffff",
                    borderRadius: "3rem",
                    padding: "0.5rem 3rem",
                    marginTop: "3rem",
                    marginBottom: "8rem",
                    marginLeft: "1rem",
                  }}
                >
                  Go To Dashboard
                </Button>
              </>
            )}
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}

export default CheckoutFormtest;
