const deviceTypeList = [
  { name: "Adapter", value: "Adapter" },
  { name: "Cables", value: "Cables" },
  { name: "Camera", value: "Camera" },
  { name: "Cell Phone", value: "Cell Phone" },
  { name: "Display", value: "Display" },
  { name: "Hard Disk", value: "Hard Disk" },
  { name: "Hardware", value: "Hardware" },
  { name: "IT Device", value: "IT Device" },
  { name: "Keyboard", value: "Keyboard" },
  { name: "Keyboard & mouse", value: "Keyboard & mouse" },
  { name: "Laptop", value: "Laptop" },
  { name: "License", value: "License" },
  { name: "Mouse", value: "Mouse" },
  { name: "Pendrive", value: "Pendrive" },
  { name: "RAM", value: "RAM" },
  { name: "SD Cards", value: "SD Cards" },
];

export default deviceTypeList;
