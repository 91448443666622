import { useState } from "react";
import { useNavigate } from "react-router";

import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    // 👇️ navigate to Dashboard
    navigate("/dashboard");
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    // add logic to authenticate via service file
    if (email === "admin@test.com" && password === "Admin123") {
      navigateToDashboard();
    } else {
      alert("Invalid credentials.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-image"></div>
      <div className="login-form">
        <div className="login-title pb-1">
          <h3>Welcome to Inventory</h3>
          <h3 className="bold">Asset Management</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group pb-1">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              placeholder="hello@example.com"
              className="email-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="password-input"
              placeholder="*********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <p className="pb-1">
            <button type="button">Forgot Password?</button>
          </p>
          <div className="text-center">
            <button type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
