import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
// import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";

import Header from "../Header/Header";

import "./Dashboard.css";
import deviceTypeList from "./DeviceType";
import UploadExcel from "../UploadExcel/UploadExcel";

const Dashboard = () => {
  const currentDate = dayjs();

  const [assetList, setAssetList] = useState([
    {
      CheckoutDate: "",
      PONumber: "",
      Status: "",
      FacilityName: "",
      Order: "",
      IssuedQty: "",
      RITMNumber: "",
      device_type: "",
      UserEID: "",
      WBSeUsed: "",
      Issuedby: "",
      Projectmanager: "",
      Link: "",
      ServiceTag: "",
      ModelNo: "",
      PRNumber: "",
      Assetcode: "",
      Qty: "1",
      CheckinDate: "",
      Location: "",
      uuid: "",
      DEVICES: "",
      DUName: "",
      Username: "",
    },
  ]); // asset list
  const [assetCloneList, setAssetCloneList] = useState([]); // copy of asset list (do I need this?)

  const [deviceType, setdeviceType] = useState(""); // filter on device type
  // const [selectedYear, setSelectedYear] = useState(""); // filter on year
  const [searchInput, setSearchInput] = useState(""); // filter on search input
  
  const [selectedAsset, setSelectedAsset] = useState({
    UserEID: "",
    CheckoutDate: "",
    WBSeUsed: "",
    PONumber: "",
    Issuedby: "",
    Projectmanager: "",
    Link: "",
    Status: "",
    FacilityName: "",
    Order: "",
    ServiceTag: "",
    ModelNo: "",
    PRNumber: "",
    Assetcode: "",
    RITMNumber: "",
    Qty: "",
    Location: "",
    uuid: "",
    DEVICES: "",
    device_type: "",
    DeviceType: "",
    DUName: "",
    Username: "",
  });

  const [checkOutOpen, setcheckOutOpen] = useState(false);
  const [checkOutData, setCheckOutData] = useState({
    uuid: "",
    UserEID: "",
    Projectmanager: "",
    DUName: "",
    IssuedQty: 1,
    Issuedby: "",
    CheckoutDate: "",
  });

  const [checkInOpen, setcheckInOpen] = useState(false);
  const [checkInData, setCheckInData] = useState({
    uuid: "",
    UserEID: "",
    Projectmanager: "",
    DUName: "",
    IssuedQty: 1,
    Issuedby: "",
    CheckoutDate: "",
  });

  const [message, setMessage] = useState("");

  const fetchAssetList = () => {
    fetch("https://q7ghj4lavb.execute-api.us-east-1.amazonaws.com/dev/am/list")
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.body);
        setAssetList(data.body);
        setAssetCloneList(data.body);
        // console.log(assetList);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchAssetList();
  }, []);

  const navigate = useNavigate();
  const navigateToAssetCheckout = () => {
    // 👇️ navigate to AssetCheckout
    navigate("/create-new");
  };

  // might need to change logic here
  const years = Array.from(
    { length: 6 },
    (_, index) => new Date().getFullYear() - index
  );
  // filter section
  const clearFilter = () => {
    setSearchInput("");
    setdeviceType("");
    // setSelectedYear("");
    setAssetList(assetCloneList);
  };

  const handleDataFiltering = () => {
    let filteredData = [];

    if (searchInput && searchInput.trim() !== "") {
      filteredData = assetCloneList.filter((asset) => {
        if (
          asset.DEVICES.includes(searchInput) ||
          asset.DEVICES.toLowerCase().includes(searchInput.toLowerCase()) ||
          asset.FacilityName.includes(searchInput) ||
          asset.FacilityName.toLowerCase().includes(searchInput.toLowerCase())
        ) {
          return asset;
        }
      });
    }

    if (deviceType) {
      if (filteredData.length > 0) {
        filteredData = filteredData.filter((asset) => {
          if (asset.device_type.toLowerCase() === deviceType.toLowerCase()) {
            return asset;
          }
        });
      } else {
        filteredData = assetCloneList.filter((asset) => {
          if (asset.device_type.toLowerCase() === deviceType.toLowerCase()) {
            return asset;
          }
        });
      }
    }

    // if (selectedYear) {
    //   if (filteredData.length > 0) {
    //     filteredData = filteredData.filter((asset) => {
    //       if (asset.CheckoutDate !== "") {
    //         const assetYear = dayjs(asset.CheckoutDate).year(); // requires date format to be yyyy-mm-dd

    //         if (assetYear === selectedYear) {
    //           return asset;
    //         }
    //       }
    //     });
    //   } else {
    //     filteredData = assetCloneList.filter((asset) => {
    //       if (asset.CheckoutDate !== "") {
    //         const assetYear = dayjs(asset.CheckoutDate).year(); // requires date format to be yyyy-mm-dd

    //         if (assetYear === selectedYear) {
    //           return asset;
    //         }
    //       }
    //     });
    //   }
    // }

    setAssetList(filteredData);
  };
  // END - filter section

  // Common Modal closing function
  const closeModalPopup = (modal, isOpen) => {
    setMessage("");
    switch (modal) {
      case "checkin":
        setcheckInOpen(isOpen);
        break;
      case "checkout":
        setcheckOutOpen(isOpen);
        break;
      default:
        break;
    }
  };
  // END - Common Modal closing function

  // Common Modal open function
  const openModalPopup = (event, modal, isOpen, data) => {
    setMessage("");
    switch (modal) {
      case "checkin":
        setcheckInOpen(isOpen);
        break;
      case "checkout":
        setcheckOutOpen(isOpen);
        break;
      default:
        break;
    }
  };
  // END - Common Modal open function

  // Set state on modal popup input field change
  const handleInputChange = (mode, key, value) => {
    if (key === "CheckoutDate") {
      value = dayjs(value.$d).format("YYYY-MM-DD");
    }

    switch (mode) {
      case "checkout":
        setCheckOutData({ ...checkOutData, [key]: value });
        break;
      case "checkin":
        setCheckInData({ ...checkInData, [key]: value });
        break;
      default:
        break;
    }
  };
  // END - Set state on modal popup input field change

  const handleOnCellClick = (params) => {
    // console.log(params.row);
    setSelectedAsset(params.row);

    if (params.field === "Qty") {
      setCheckOutData({
        ...checkOutData,
        UserEID: params.row.UserEID,
        // DeviceType: params.row.device_type,
        IssuedQty: params.row.IssuedQty ? parseInt(params.row.IssuedQty) : 0,
        CheckoutDate: params.row.CheckoutDate,
        Projectmanager: "", //params.row.Projectmanager,
        DUName: "", // params.row.DUName,
        Issuedby: params.row.Issuedby,
        uuid: params.row.uuid,
      });
    } else if (params.field === "issueQty") {
      setCheckInData({
        ...checkInData,
        UserEID: params.row.UserEID,
        // DeviceType: params.row.device_type,
        IssuedQty: params.row.IssuedQty ? parseInt(params.row.IssuedQty) : 0,
        CheckoutDate: params.row.CheckoutDate,
        Projectmanager: params.row.Projectmanager,
        DUName: params.row.DUName,
        Issuedby: params.row.Issuedby,
        uuid: params.row.uuid,
      });
    }
  };

  // on submitting modal - checkin and checkout
  const handleModalSubmit = (mode) => {
    let requestObj = {};
    if (mode === "checkin") {
      requestObj = { ...checkInData };
    } else if (mode === "checkout") {
      requestObj = { ...checkOutData };
    }

    // issued qty is of type string hence converting to string
    requestObj.IssuedQty = "" + requestObj.IssuedQty;

    fetch(
      `https://q7ghj4lavb.execute-api.us-east-1.amazonaws.com/dev/am/${mode}`,
      {
        method: "PUT",
        body: JSON.stringify(requestObj),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data.statusCode === 200) {
          setMessage(data.body);
          setTimeout(() => {
            closeModalPopup(mode, false);
          }, 1000);
          //call list API again
          fetchAssetList();
        }
      });
    // setcheckOutOpen(false);
    // setcheckInOpen(false);
  };
  // END - on submitting modal - checkin and checkout

  // edit asset
  const editAsset = (e, params) => {
    // console.log(params.row);
    navigate("/create-new", { state: params.row });
  };
  // END - edit asset

  const columns = [
    {
      field: "DEVICES",
      headerName: "Devices",
      width: 300,
      sortable: false,
      headerClassName: "asset-list-table-header",
      // cellClassName: "asset-list-table--device-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Qty",
      headerName: "Total Qty.",
      width: 150,
      sortable: false,
      headerClassName: "asset-list-table-header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {params.value}
            <IconButton
              title="Check Out"
              sx={{ color: "#F00" }}
              aria-label="check out"
              onClick={(event) =>
                openModalPopup(event, "checkout", true, params)
              }
            >
              <ErrorOutlineIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "issueQty",
      headerName: "Issued Qty.",
      width: 120,
      sortable: false,
      headerClassName: "asset-list-table-header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row.IssuedQty ? params.row.IssuedQty : 0}
            <IconButton
              title="Check In"
              sx={{ color: "#F00" }}
              aria-label="check in"
              onClick={(event) =>
                openModalPopup(event, "checkin", true, params)
              }
            >
              <ErrorOutlineIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "PRNumber",
      headerName: "PR Number",
      width: 130,
      sortable: false,
      headerClassName: "asset-list-table-header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value === "nan" ? "" : params.value;
      },
    },
    {
      field: "FacilityName",
      headerName: "Facility Name",
      width: 130,
      sortable: false,
      headerClassName: "asset-list-table-header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value === "nan" ? "" : params.value;
      },
    },
    {
      field: "ModelNo",
      headerName: "Model No.",
      width: 130,
      sortable: false,
      headerClassName: "asset-list-table-header",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Link",
      headerName: "Link",
      width: 200,
      sortable: false,
      headerClassName: "asset-list-table-header",
      cellClassName: "asset-list-table--link-cell",
      flex: 1,
      headerAlign: "center",
      // align: "center",
      renderCell: (params) => {
        return (
          <>
            <a
              href={params.value}
              title={params.value}
              target="_blank"
              rel="noreferrer"
            >
              {params.value === "nan" ? "" : params.value}
            </a>
          </>
        );
      },
    },
    // {
    //   field: "addToInventory",
    //   headerName: "Add to Inventory",
    //   width: 150,
    //   sortable: false,
    //   headerClassName: "asset-list-table-header",
    //   cellClassName: "asset-list-table--addToInventory-cell",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <IconButton
    //           aria-label="add to inventory"
    //           onClick={(event) =>
    //             openModalPopup(event, "inventory", true, params)
    //           }
    //         >
    //           Add to Inventory
    //         </IconButton>
    //       </>
    //     );
    //   },
    // },
    {
      field: "edit",
      headerName: "Edit",
      width: 80,
      sortable: false,
      headerClassName: "asset-list-table-header",
      headerAlign: "center",
      align: "center",
      cellClassName: "asset-list-table--addToInventory-cell",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="edit row"
              title="Edit asset"
              onClick={(event) => editAsset(event, params)}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Header />
      <div className="filter-section">
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-search">
            Search by Device or Facility Name
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            className="search-box"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //       onClick={(event) => handleDataFiltering(event, "search")}
            //     >
            //       <SearchIcon />
            //     </IconButton>
            //   </InputAdornment>
            // }
            aria-describedby="search icon"
            inputProps={{
              "aria-label": "search",
            }}
            label="Search by Device or Facility Name"
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: "20ch" }} className="device-type">
          <Select
            value={deviceType}
            defaultValue=""
            // onChange={(event) => handleDataFiltering(event, "device_type")}
            onChange={(e) => setdeviceType(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">Select Device Type</MenuItem>
            {deviceTypeList.map((device) => (
              <MenuItem value={device.value} key={device.value}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl sx={{ m: 1, width: "20ch" }} className="date-mr">
          <Select
            inputProps={{ "aria-label": "Select Year" }}
            value={selectedYear}
            displayEmpty
            onChange={(e) => setSelectedYear(e.target.value)}
            defaultValue=""
            // onChange={handleYearChange}
          >
            <MenuItem value="">Select Year</MenuItem>
            {years.map((year, index) => (
              <MenuItem value={year} key={index}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <FormControl sx={{ alignSelf: "center" }}>
          <IconButton onClick={() => handleDataFiltering()}>
            <SearchIcon />
          </IconButton>
        </FormControl>
        <FormControl sx={{ alignSelf: "center", marginRight: "10em" }}>
          <IconButton onClick={() => clearFilter()}>
            <ClearIcon />
          </IconButton>
        </FormControl>
        <Grid container className="functionbuttons">
        <FormControl sx={{ m: 1, width: "15ch" }} className="btn">
          <Button
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={navigateToAssetCheckout}
          >
            Create New
          </Button>
        </FormControl>
        <FormControl sx={{ m: 1, width: "15ch" }} className="btn">
          <UploadExcel />
        </FormControl>
        </Grid>
      </div>
      {/* table to list */}
      <DataGrid
        className="asset-list-table"
        rows={assetList && assetList.length > 0 ? assetList : []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 30]}
        getRowId={(row) => row.uuid}
        disableColumnMenu
        sx={{
          color: "#331773",
          fontFamily: "DM Sans, sans-serif",
          fontSize: "16px",
          "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
        onCellClick={handleOnCellClick}
      />
      {/* END - table to list */}
      <br /> <br />
      {/* Check-out modal popup */}
      <>
        <Dialog
          maxWidth="md"
          open={checkOutOpen}
          onClose={() => closeModalPopup("checkout", false)}
        >
          <DialogContent>
            <IconButton
              onClick={() => closeModalPopup("checkout", false)}
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                padding: 0,
                outline: "none",
              }}
            >
              <CloseIcon />
            </IconButton>
            <TableContainer className="modal-table">
              <Table
                sx={{
                  minWidth: 650,
                  [`& .${tableCellClasses.root}`]: {
                    borderTop: "none",
                  },
                }}
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    "& th": {
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>Email Id</TableCell>
                    <TableCell>Proj. Manager</TableCell>
                    <TableCell>DU Name</TableCell>
                    <TableCell>Units Issued</TableCell>
                    <TableCell>Approver</TableCell>
                    <TableCell>Date </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td": {
                      paddingRight: "0.5em",
                      paddingLeft: "0.5em",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Email Id"
                        type="text"
                        size="small"
                        name="UserEID"
                        // defaultValue={checkOutData.UserEID}
                        onChange={(e) =>
                          handleInputChange(
                            "checkout",
                            "UserEID",
                            e.target.value
                          )
                        }
                        sx={{ width: "20ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Proj Manager"
                        type="text"
                        size="small"
                        name="Projectmanager"
                        // defaultValue={checkOutData.Projectmanager}
                        onChange={(e) =>
                          handleInputChange(
                            "checkout",
                            "Projectmanager",
                            e.target.value
                          )
                        }
                        sx={{ width: "20ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="DU Name"
                        type="text"
                        size="small"
                        name="DUName"
                        // defaultValue={checkOutData.DUName}
                        onChange={(e) =>
                          handleInputChange(
                            "checkout",
                            "DUName",
                            e.target.value
                          )
                        }
                        sx={{ width: "15ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Units"
                        type="number"
                        size="small"
                        name="IssuedQty"
                        // defaultValue={checkOutData.IssuedQty}
                        onChange={(e) => {
                          handleInputChange(
                            "checkout",
                            "IssuedQty",
                            e.target.value
                          );
                        }}
                        sx={{ width: "10ch" }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: parseInt(selectedAsset.Qty),
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Approver"
                        type="text"
                        size="small"
                        name="Issuedby"
                        // defaultValue={checkOutData.Issuedby}
                        onChange={(e) =>
                          handleInputChange(
                            "checkout",
                            "Issuedby",
                            e.target.value
                          )
                        }
                        sx={{ width: "15ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            views={["year", "month", "day"]}
                            label="Select Date"
                            slotProps={{ textField: { size: "small" } }}
                            onChange={(e) =>
                              handleInputChange("checkout", "CheckoutDate", e)
                            }
                            format="YYYY-MM-DD"
                            defaultValue={dayjs()}
                            value={dayjs()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <p className="message">{message}</p>
          </DialogContent>
          <DialogActions className="btn-modal">
            <Button onClick={() => handleModalSubmit("checkout")}>
              Check out
            </Button>
          </DialogActions>
        </Dialog>
      </>
      {/* END - Check-out modal popup */}
      {/* Check-in modal popup */}
      <>
        <Dialog
          maxWidth="md"
          open={checkInOpen}
          onClose={() => closeModalPopup("checkin", false)}
        >
          <DialogContent>
            <IconButton
              onClick={() => closeModalPopup("checkin", false)}
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                padding: 0,
                outline: "none",
              }}
            >
              <CloseIcon />
            </IconButton>

            <TableContainer className="modal-table">
              <Table
                sx={{
                  minWidth: 650,
                  [`& .${tableCellClasses.root}`]: {
                    borderTop: "none",
                  },
                }}
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    "& th": {
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableRow>
                    {/* <TableCell>Device Type</TableCell> */}
                    <TableCell>Email Id</TableCell>
                    <TableCell>Proj. Manager</TableCell>
                    <TableCell>DU Name</TableCell>
                    <TableCell>Units Issued</TableCell>
                    <TableCell>Approver</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td": {
                      paddingRight: "0.5em",
                      paddingLeft: "0.5em",
                    },
                  }}
                >
                  <TableRow>
                    {/* <TableCell>
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="modal-table--select"
                        size="small"
                        name="DeviceType"
                        defaultValue={checkInData.DeviceType}
                        onChange={(e) =>
                          handleInputChange(
                            "checkin",
                            "DeviceType",
                            e.target.value
                          )
                        }
                        sx={{ width: "20ch" }}
                        value={checkInData.DeviceType}
                      >
                        <MenuItem value="">Select Device Type</MenuItem>
                        {deviceTypeList.map((device) => (
                          <MenuItem value={device.value} key={device.value}>
                            {device.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell> */}
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Email Id"
                        type="text"
                        size="small"
                        name="Email Id"
                        defaultValue={checkInData.UserEID}
                        onChange={(e) =>
                          handleInputChange(
                            "checkin",
                            "Email Id",
                            e.target.value
                          )
                        }
                        sx={{ width: "20ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Proj Manager"
                        type="text"
                        size="small"
                        name="Projectmanager"
                        defaultValue={checkInData.Projectmanager}
                        onChange={(e) =>
                          handleInputChange(
                            "checkin",
                            "Projectmanager",
                            e.target.value
                          )
                        }
                        sx={{ width: "20ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="DU Name"
                        type="text"
                        size="small"
                        name="DUName"
                        defaultValue={checkInData.DUName}
                        onChange={(e) =>
                          handleInputChange("checkin", "DUName", e.target.value)
                        }
                        sx={{ width: "15ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Units"
                        type="number"
                        size="small"
                        name="IssuedQty"
                        defaultValue={checkInData.IssuedQty}
                        onChange={(e) =>
                          handleInputChange(
                            "checkin",
                            "IssuedQty",
                            e.target.value
                          )
                        }
                        sx={{ width: "10ch" }}
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="modal-table--input"
                        label="Approver"
                        type="text"
                        size="small"
                        name="Issuedby"
                        defaultValue={checkInData.Issuedby}
                        onChange={(e) =>
                          handleInputChange(
                            "checkin",
                            "Issuedby",
                            e.target.value
                          )
                        }
                        sx={{ width: "15ch" }}
                      />
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            views={["year", "month", "day"]}
                            label="Select Date"
                            slotProps={{ textField: { size: "small" } }}
                            onChange={(e) =>
                              handleInputChange("checkin", "CheckoutDate", e)
                            }
                            format="YYYY-MM-DD"
                            defaultValue={dayjs()}
                            value={dayjs(checkInData.CheckoutDate)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <p className="message">{message}</p>
          </DialogContent>
          <DialogActions className="btn-modal">
            <Button onClick={() => handleModalSubmit("checkin")}>
              Check in
            </Button>
          </DialogActions>
        </Dialog>
      </>
      {/* END - Check-in modal popup */}
    </>
  );
};

export default Dashboard;
