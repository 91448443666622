import React from "react";
import { useNavigate } from "react-router";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
// import userlogo from '../../assets/images/userlogo.png';
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();
  const navigateToLogin = () => {
    // 👇️ navigate to Login page
    navigate("/");
  };

  const navigateToDashboard = () => {
    // 👇️ navigate to Dashboard page
    navigate("/dashboard");
  };

  return (
    <>
      <div className="header-container">
        <div className="header-container-left">
          <h1 className="project-title" onClick={navigateToDashboard}>
            Asset Inventory Management
          </h1>
        </div>
        <div className="header-container-right">
          <AccountCircleOutlinedIcon />
          <p className="username">Admin</p>
          <NotificationImportantOutlinedIcon
            sx={{ paddingRight: "0.3em", paddingLeft: "0.3em" }}
          />
          <ExitToAppOutlinedIcon
            onClick={navigateToLogin}
            sx={{
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className="asset-checkout-image"> </div>
    </>
  );
};

export default Header;
